import type {
	MonthFilter,
	MonthFilterNames,
	MovementTypeObject,
} from '@/types/types';

import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const months: { value: MonthFilter; label: MonthFilterNames }[] = [
	{ value: 1, label: 'Ocak' },
	{ value: 2, label: 'Şubat' },
	{ value: 3, label: 'Mart' },
	{ value: 4, label: 'Nisan' },
	{ value: 5, label: 'Mayıs' },
	{ value: 6, label: 'Haziran' },
	{ value: 7, label: 'Temmuz' },
	{ value: 8, label: 'Ağustos' },
	{ value: 9, label: 'Eylül' },
	{ value: 10, label: 'Ekim' },
	{ value: 11, label: 'Kasım' },
	{ value: 12, label: 'Aralık' },
];

export function range(start: number, end: number, step = 1): number[] {
	if (step <= 0) {
		throw new Error('Step must be a positive number');
	}

	const result: number[] = [];
	for (let i = start; i < end; i += step) {
		result.push(i);
	}

	return result;
}

export function setLocalStorage<T>(key: string, value: T) {
	localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorage<T>(key: string): T | null {
	const item = localStorage.getItem(key);
	if (!item) {
		return null;
	}

	return JSON.parse(item);
}

export function formatDateTurkish(dateString: string) {
	const date = new Date(dateString);
	const formatted = new Intl.DateTimeFormat('tr-TR', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	}).format(date);
	return formatted.replace(
		/(\w+)\s(\w+)\s(\d+)/,
		(_, day, month, year) => `${day} ${month.toUpperCase()} ${year}`,
	);
}

export function imageUrl(image: string) {
	return `https://cdn.veritfy.com/uploads/${image}`;
}

export const MovementType: MovementTypeObject = {
	1: 'Satış',
	2: 'Satış İptali',
	3: 'Giriş',
	4: 'Çıkış',
	5: 'Sayım',
	6: 'Koreksiyon Artışı',
	7: 'Koreksiyon Azalışı',
	8: 'Malzeme Tüketimi',
	9: 'Ürün Üretimi',
	10: 'Transfer',
	11: 'Ürün üretim iptali',
	12: 'Malzeme tüketim iptali',

	SATIS: 1,
	SATIS_IPTALI: 2,
	GIRIS: 3,
	CIKIS: 4,
	SAYIM: 5,
	KOREKSIYON_ARTISI: 6,
	KOREKSIYON_AZALISI: 7,
	MALZEME_TUKETIMI: 8,
	URUN_URETIMI: 9,
	TRANSFER: 10,
	URUN_URETIM_IPTALI: 11,
	MALZEME_TUKETIM_IPTALI: 12,
};

Object.freeze(MovementType);
